/* Common Modules.css */

/* Common button containers */
.login-btn-container,
.cancel-btn-container {
  display: flex;
  justify-content: center;
  border-radius: 10px;
}

/* Common button styles */
.btn.login-button,
.btn.cancel-button {
  width: 200px;
  height: 40px;
  margin-bottom: 1.2rem !important;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 5px;
  border: none;
}

/* Login button specific styles */
.btn.login-button {
  background-color: #055837 !important;
  color: #ffcc01 !important;
}

/* Cancel button specific styles */
.btn.cancel-button {
  background-color: #ffcc01 !important;
  color: #055837 !important;
}

/* Hover effect for buttons */
.btn.login-button:hover {
  background-color: #ffcc01 !important;
  color: #055837 !important;
}

.btn.cancel-button:hover {
  background-color: #055837 !important;
  color: #ffcc01 !important;
}

/* Account link container */
.account-link {
    font-size: 15px;
  }
  
  /* Account link paragraph styles */
  .account-link p {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  
  .account-link a {
    text-decoration: none !important;
    color:#055837 !important;
  }
  
  .account-link a:hover {
    text-decoration: underline !important;
  }






/* Responsive styles */
@media screen and (max-width: 768px) {
  /* For tablets and smaller screens */
  .btn.login-button,
  .btn.cancel-button {
    width: 160px;
    font-size: 16px;
    height: 36px;
  }

  .account-link {
    font-size: 14px;
  }
}

@media screen and (max-width: 576px) {
  /* For smartphones */
  .btn.login-button,
  .btn.cancel-button {
    width: 180px;
    font-size: 14px;
    height: 32px;
  }

  .account-link {
    font-size: 12px;
  }
}
