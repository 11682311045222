/* Container styles */
.header-container {
    background-color: white;
  }
  
  .logo-container {
    text-align: center;
  }
  
  .header-image {
    max-width: 100%;
    height: auto;
    max-height: 112px;
    display: block;
    margin: 0 auto;
  }
  
  #dashboard-dropdown:hover,
  #user-dropdown:hover {
    text-decoration: underline;
    color: #055837;
  }
  

/* Dropdown Menu Styles */
.custom-dropdown-menu {
  border: 2px solid #ffcc01;
  border-radius: 5px;
  background-color: white; /* Changed from transparent to white */
  padding: 0; 
  text-align: center; 
  display: flex; 
  flex-direction: column; 
  align-items: center; /* Centers the menu items */
  min-width: var(--dropdown-width); /* Dynamic width */
  position: absolute; /* Ensures the dropdown overlays correctly */
  z-index: 1000;
}

.custom-dropdown-item {
  color: #000; /* Add text color for better visibility */
}

/* Optional hover effect for dropdown items */
.custom-dropdown-item:hover {
  background-color: #ffcc01; /* Highlight background on hover */
}

  
  /* Dropdown Item Styles */
  .custom-dropdown-item {
    color: #055837;
    padding: 10px 20px;
    width: 100%;
    background-color: transparent;
    border-radius: 5px;
    text-align: center;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  /* Hover effect for dropdown item */
  .custom-dropdown-item:hover {
    background-color: rgba(255, 255, 0, 0.1);
    color: #055837;
  }
  
  /* Responsive styling for smaller screens */
  @media screen and (max-width: 768px) {
    .header-image {
      max-height: 100px;
    }
  }
  
  @media screen and (max-width: 576px) {
    .header-image {
      max-height: 80px;
    }
  }
  