.reminder_date_chooser{
    text-decoration: none;
    color: black;
}

.reminder_date_chooser:hover{
    cursor: pointer;
    text-decoration: none;
    color: var(--green);
}

.reminderdate_wrapper{
    display: flex;
}
.label_box{
    margin: auto;
}

.icon-transition {
    transition: transform 0.3s ease;
  }
  
  .icon-transition:hover {
    transform: scale(1.2);
  }
  