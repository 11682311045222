.sub_heading_title_p{
    font-weight: bold;
    font-size: 16px;
}

.nav_top_bar{
    display: block;
    width: 100%;
    padding: 10px 0px;
    border-radius: 5px;
    font-size: 18px;
    text-decoration: none;
    color: var(--green);
    transition: .2s;
}

.nav_top_bar:hover{
    text-decoration: none;
    color: var(--green);
    background-color: var(--yellow);
    transition: .2s;
}
.accordion-button{
    font-size: 16px;
    font-weight: bold;
}


@media(max-width:600px){
    .btn_margin{
        margin-top: 10px;
    }
}
