.tom-select-dropdown {
    position: absolute !important;
    z-index: 1050 !important;
    top: 100%; /* Dropdown appears just below the input */
    left: 0;
    width: 100%;
    max-height: 200px; /* Adjust as needed */
    overflow-y: auto;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  