*{
    --green: #055837;
    --yellow: #fecc00;
}



.main-wrapper{
    display: flex;
  }
  
  .main-wrapper .sub-wrapper-sidebar{
    width: 18%;
    overflow-y: auto;
    /* height: 90vh; */
    padding: 0 14px 0 0;
    background: white;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    
  }
  
  .main-wrapper .sub-wrapper-main{
    padding: 0 20px;
    width: 82%;
  }
  

.sidebar-nav {
    padding: 0;
}

.sidebar-nav .nav-item {
    /* margin-bottom: 10px; */
    list-style: none;
}

.sidebar-nav .nav-item:last-child {
    margin-bottom: 30px;
}

.sidebar-nav .nav-link.collapsed {
    color: var(--green);
    text-decoration: none;
}

.sidebar-nav .nav-link.collapsed:hover {
    color: var(--green);
    text-decoration: underline;
}


.sidebar-nav .nav-link {
    display: flex;
    align-items: center;
    font-size: 15px;
    font-weight: 500;
    transition: 0.3s;
    padding: 10px 15px;
    /* border-radius: 4px; */
    margin: 5px 0px;
    font-weight: bold;
}

.sidebar-nav .nav-link:hover {
    cursor: pointer;
    background-color: var(--yellow);

    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
}

.sidebar-nav .nav-link i {
    margin-right: 10px;
    margin-left: 10px;

}

.active-newbar {
    background-color: var(--green) !important;
    color: var(--yellow) !important;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
}

.nav-item:first-child {
    margin-top: 15px;
}

.sidebar-wrapper::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

.sidebar-wrapper::-webkit-scrollbar {
    width: 6px;
    background-color: #f1f1f1;
}

.sidebar-wrapper::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 3px;
}

.sidebar-wrapper::-webkit-scrollbar-thumb:hover {
    background-color: #555;
}


/* Colors */

.bg-green{
    background-color: var(--green);
}
.text-green{
    color: var(--green);
}

.bg-yellow{
    background-color: var(--yellow);
}

.text-yellow{
    color: var(--yellow);
}
/* End */

/* Collpase */
.accordion-button:not(.collapsed){
    /* background-color: var(--green);
    color: var(--yellow); */

    background-color: white;
    /* color: var(--yellow); */

}
/* END */