/* Heading.css */

.bg-green {
    background-color: #055837 !important;
  }
  
  .text-white {
    --bs-text-opacity: 1;
    color: rgb(255 255 255) !important;
  }
  
  .text-center {
    text-align: center !important;
  }
  
  .pb-1 {
    padding-bottom: .25rem !important;
  }
  
  .pt-1 {
    padding-top: .25rem !important;
  }
  
  /* Responsive styles */
  @media screen and (max-width: 768px) {
    /* For tablets and smaller screens */
    .pt-1, .pb-1 {
      padding-top: .5rem !important;
      padding-bottom: .5rem !important;
    }
  }
  
  @media screen and (max-width: 576px) {
    /* For smartphones */
    .pt-1, .pb-1 {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
    }
    
    /* Smaller font size for smartphones */
    .text-white {
      font-size: 14px !important;
    }
  }
  