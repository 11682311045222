// Core variables and mixins
@import '../summernote/font.scss';
@import '../summernote/elements.scss';
@import "scss/variables.scss";
@import "scss/mixins.scss";

@import "scss/common.scss";
@import "scss/toolbar.scss";
@import "scss/btn-group.scss";
@import "scss/buttons.scss";
@import "scss/dropdown.scss";
@import "scss/modal.scss";
@import "scss/form.scss";
@import "scss/tooltip.scss";
@import "scss/popover.scss";

@import '../summernote/common.scss';

.note-editor {
  .note-editing-area {
    .note-editable {
      table {
        width: 100%;
        border-collapse: collapse;

         td, th {
          border: 1px solid #ececec;
          padding: 5px 3px;
        }
      }

      a {
        background-color: inherit;
        text-decoration: inherit;
        font-family: inherit;
        font-weight: inherit;
        color: #337ab7;
      }

      a:hover,
      a:focus {
        color: #23527c;
        text-decoration: underline;
        outline: 0;
      }

      figure {
        margin: 0;
      }
    }
  }
}

/* Dialog
 ------------------------------------------*/
.note-modal {
  .note-modal-body {
    label {
      margin-bottom: 2px;
      padding: 2px 5px;
      display: inline-block;
    }

     .help-list-item:hover {
      background-color: #e0e0e0;
    }
  }

   // [workaround] firefox fileinput
  @-moz-document url-prefix() {
    .note-image-input {
      height: auto;
    }
  }
}


.help-list-item {
  label {
    margin-bottom:5px;
    display:inline-block;
  }
}
