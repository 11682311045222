.notes-chat-card {
  height: 80vh; /* Adjust the height of the card as needed */
  overflow-y: auto; /* Enable vertical scrolling for messages */
  position: relative;
}

.notes-chat-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  width: 100%;
}

.message-list {
  flex-grow: 1; /* Allow message list to expand and fill available space */
  overflow-y: auto; /* Enable vertical scrolling for messages */
  padding: 0 1rem; /* Adjust padding as needed */
}

.message {
  margin-bottom: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.2rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  /* background-color: #e0f7fa;  */
  background-color: #dee2e6; 

  border-radius: 15px; /* Rounded corners for cloud-like shape */
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for cloud effect */
}

/* Add this pseudo-element for the arrow */
.message::before {
  content: "";
  position: absolute;
  top: 0; /* Adjust based on your preference */
  left: -20px; /* Adjust based on your preference */
  border-width: 20px; /* Adjust based on your preference */
  border-style: solid;
  /* border-color: #e0f7fa transparent transparent transparent; */
  border-color: #dee2e6 transparent transparent transparent;



}

.message.sent-file {
  align-self: flex-start;
}

.message .message-options {
  position: absolute;
  top: 0rem;
  right: 0rem;
}

.dropdown-menu {
  min-width: 100%; /* Ensures full width */
  width: auto; /* Let the width grow according to the content */
}


.message-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  /* padding-bottom: 15px; */
}

.message-header {
  position: absolute; /* Use absolute positioning */
  bottom: 5px; /* Position it at the bottom */
  right: 0px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: default;
}

.added_by_name {
  font-weight: bold;
  color: #055837;
}

.read-less {
  flex-grow: 1; /* Pushes the rest of the content to the right */
}

.message-header-read-less {
  display: flex;
  align-items: center;
  gap: 10px;
}

.added_by_name-read-less {
  font-weight: bold;
  color: #055837;
}

.message-text {
  word-wrap: break-word;
  overflow-wrap: break-word;
  margin-right: 5%;
}

.message-text .message-options {
  margin-left: auto;
}

.message-timestamp {
  font-size: 0.9rem;
  color: #999;
  text-align: right;
}

.read_more_link {
  color: #055837;
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
}

.read_more_link:hover {
  text-decoration: underline;
  text-decoration-color: #ffcc01;
  color: #055837;
}

.read_less_link {
  color: #ffcc01;
  text-decoration: none;
  cursor: pointer;
  font-weight: bold;
}

.read_less_link:hover {
  text-decoration: underline;
  text-decoration-color: #055837;
  color: #ffcc01;
}

/* .file-preview {
  width: 300px;
  height: 170px;
  object-fit: contain;
  display: block;
} */

.file-preview {
  width: 300px;
  height: 170px;
  object-fit: cover; 
  display: block;
  border: 1px solid #ffcc01;
}

.unsupported-file p {
  margin: 0;
  font-weight: bold;
}


.file-preview-box {
  width: 45%;
  height: 55vh;
  margin: 0 auto 0 auto;
  background-color: #f0f0f0;
  border: 1px solid #ffcc01;
  border-radius: 10px;
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 100;
  bottom: 5rem;
}

.file-preview-content {
  display: flex;
  flex-direction: column;
  /* align-items: flex-start;  */
}

.file-preview-actions {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 0.5rem;
}

.file-cancel-button,
.file-send-button {
  font-size: 1.2rem;
  transition: transform 0.3s ease;
  padding: 0.5rem;
}

.file-cancel-button {
  color: red;
}

.file-send-button {
  color: #055837;
}

.file-send-button:hover {
  transform: scale(1.1);
  color: #055837;
}

.file-cancel-button:hover {
  transform: scale(1.1);
  color: red;
}

.file-name {
  text-align: center;
  color: black;
}

.input-row {
  background-color: #f8f9fa;
  padding: 0.5rem;
  border: 1px solid #ccc;
  position: sticky;
  width: 100%;
  border-radius: 5px;
  margin-top: 0.5rem;
}

.input-row .form-control,
.input-row .send-button,
.input-row .file-upload-icon {
  height: 2.7rem;
}

.input-row .form-control {
  max-height: 10rem;
  overflow-y: auto;
  padding: 0.5rem;
}

.file-upload-icon input[type="file"] {
  display: none;
}

.file-upload-icon label {
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 50%;
  padding: 0.5rem;
  background-color: #ffcc01;
  color: #055837;
}

.file-upload-icon label:hover {
  background-color: #055837;
  color: #ffcc01;
}

.send-button {
  font-size: 1.2rem;
  padding: 0.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #055837;
  color: #ffcc01;
  border: none;
}

.send-button:hover {
  background-color: #ffcc01;
  color: #055837;
  border: none;
}

.file-options {
  margin-bottom: 30px;
  margin-right: 0rem;
  display: flex;
  justify-content: flex-end;
}

.file-options .dropdown-menu {
  right: 0rem;
  left: auto;
}

.reply-info {
  font-size: 0.85em;
  color: #555;
}

@media (max-width: 576px) {
  .file-upload-icon {
    display: none;
  }
}
