.ql-snow{
    cursor: pointer !important;
}
@media (max-width:768px){
    .test_mail_btn{
        width: 100%;
    }

}
@media(min-width:768px){
    .label_mail{
        width: 5.4%;
    }
}

.note-editable{
    background-color: white;
}