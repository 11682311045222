.note-btn {
  display: inline-block;
  font-weight: 400;
  margin-bottom: 0;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  background-image: none;
  border: 1px solid $btn-default-border;
  white-space: nowrap;
  outline: 0;
  @include button-maker($btn-default-color, $btn-default-bg, $btn-default-hover-bg,  $btn-default-active-bg, $btn-default-border);
  @include button-size($padding-base-vertical, $padding-base-horizontal, $font-size, $line-height, $btn-border-radius-base);
  @include user-select(none);

  &:hover,
  &:focus,
  &.focus {
    color: $btn-default-color;
    text-decoration: none;
    border: 1px solid $btn-default-border;
    background-color: $btn-default-hover-bg;
    outline: 0;
    @include rounded(1px);
  }

  &:active,
  &.active {
    outline: 0;
    background-image: none;
    color: #333;
    text-decoration: none;
    border: 1px solid #dae0e5;
    background-color: #ebebeb;
    outline: 0;
    border-radius: 1px;
    -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, .125);
  }

  &.disabled,
  &[disabled],
  fieldset[disabled] & {
    cursor: $cursor-disabled;
    @include opacity(.65);
    @include box-shadow(none);
  }

  & > span.note-icon-caret:first-child {
    margin-left: -1px;
  }

  & > span.note-icon-caret:nth-child(2) {
    padding-left: 3px;
    margin-right: -5px;
  }
}

.note-btn-primary {
  background: #fa6362;
  color: #fff;

  &:hover,
  &:focus,
  &.focus {
    color: #fff;
    text-decoration: none;
    border: 1px solid $btn-default-border;
    background-color: #fa6362;
    @include rounded(1px);
  }

}

.note-btn-block {
  display: block;
  width: 100%;
}

.note-btn-block + .note-btn-block {
  margin-top:5px;
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.note-btn-block {
    width: 100%;
  }
}

button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
}

.close {
  float: right;
  font-size: 21px;
  line-height: 1;
  color: #000;
  opacity: .2;
}

.close:hover {
  -webkit-opacity: 1;
   -khtml-opacity: 1;
     -moz-opacity: 1;
       -ms-filter: alpha(opacity=100);
           filter: alpha(opacity=100);
          opacity: 1
}
