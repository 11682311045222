.tom-select.custom-icon .ts-control {
    position: relative;
  }
  
  .tom-select.custom-icon .ts-control::after {
    content: "▼"; /* or use a Unicode arrow */
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    color: gray;
    font-size: 12px;
  }
  