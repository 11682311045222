/* Footer.css */

.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: #055837;
    padding: 4px 0;
    color: #fff;
  }
  
  .footer h6 {
    text-align: center;
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    margin: 0;
    font-size: 14px;
    font-weight: normal;
  }
  
  /* Responsive styles */
  @media screen and (max-width: 768px) {
    /* For tablets and smaller screens */
    .footer {
      padding: 8px 0;
    }
    
    .footer h6 {
      font-size: 12px; 
    }
  }
  
  @media screen and (max-width: 576px) {
    /* For smartphones */
    .footer {
      padding: 12px 0;
    }
    
    .footer h6 {
      font-size: 10px;
    }
  }
  