/* Global CSS */
.thick-hr {
  height: 2px;
  background-color: #055837;
  border: none;
  margin: 0;
}

.hover-underline {
  color: red;
  cursor: pointer;
}

.hover-underline:hover {
  text-decoration: underline;
}

.main-wrapper {
  display: flex;
}

.main-wrapper .sub-wrapper-sidebar {
  width: 18%;
}

.main-wrapper .sub-wrapper-main {
  padding: 0 20px;
  width: 82%;
}

.text-color-green {
  color: #055837;
}

.bg-color-green {
  background-color: #055837;
}

.profile-link:hover {
  color: #055837;
}

.table-wrapper {
  background-color: white;
  padding: 5px;
  border-radius: 5px;
}

.action_buttons_top {
  padding: 2px;
  margin-bottom: 10px;
  border-radius: 5px;
}

.search-right-bar {
  float: right;
  text-align: right;
}

.message_box_icon {
  font-size: 25px;
  text-align: center;
  margin: 0 5px;
  padding: 3px 9px;
  border-radius: 5px;
}

.message_btn_color_2 {
  background: #efefef !important;
  color: red !important;
  transition: transform 0.2s ease;
}

.message_btn_color_2:hover {
  background: red !important;
  color: #efefef !important;
  transform: scale(1.15);
}

.comment_filter_active {
  background-color: red !important;
  /* border-color: blue !important; */
  transform: scale(1.35);
}

.comment_filter_active:hover {
  background-color: red !important;
  /* border-color: blue !important; */
  transform: scale(1.5);
}

.green_button_active {
  border-color: green !important;
  transform: scale(1.35);
}

.green_button_active:hover {
  border-color: green !important;
  transform: scale(1.5);
}

.red_button_active {
  border-color: red !important;
  transform: scale(1.35);
}

.red_button_active:hover {
  border-color: red !important;
  transform: scale(1.5);
}

.fz-20 {
  font-size: 20px;
}

/* Buttons */
.btn_green_yellow,
.btn_yellow_green,
.filter_flag_buttons,
.filter_flag_image {
  outline: none;
  box-shadow: none;
  border: none;
}

.btn_green_yellow {
  background-color: #055837;
  color: #fecc00;
}

.btn_green_yellow:hover {
  background-color: #fecc00;
  color: #055837;
}

.btn_yellow_green {
  background-color: #fecc00 !important;
  color: #055837 !important;
}

.btn_yellow_green:hover {
  background-color: #055837 !important;
  color: #fecc00 !important;
}

.filter_flag_image {
  width: 13px;
}

.filter_flag_image {
  /* Base styles for the image */
  width: 100px; /* Adjust as needed */
  height: auto; /* Maintain aspect ratio */
  transition: transform 0.2s ease;
}

/* Hover effect */
.filter_flag_image:hover {
  transform: scale(1.5);
}

.filter_flag_buttons {
  background: #efefef !important;
  text-align: center;
  margin: 0 5px;
  padding: 3px 9px;
  border-radius: 5px;
}

/* Media queries for responsiveness */
@media (max-width: 992px) {
  .dataTables_length,
  .searchInput {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .action_buttons_top .row {
    flex-direction: column;
    align-items: center;
  }
  .dataTables_length,
  .searchInput {
    width: 100%;
  }
  .filter_flag_buttons {
    margin-left: 0;
  }
}

/* CSS for extra small screens (phones, less than 576px) */
@media (max-width: 575.98px) {
  .mt-2 {
    margin-top: 10px !important;
  }
}

/* CSS for small screens (tablets, 576px - 767.98px) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .mt-2 {
    margin-top: 20px !important;
  }
}

/* CSS for medium screens (desktops, 768px - 991.98px) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .mt-2 {
    margin-top: 30px !important;
  }
}

/* CSS for large screens (large desktops, 992px and above) */
@media (min-width: 992px) {
  .mt-2 {
    margin-top: 40px !important;
  }
}

/* No data message */
.no-data-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
}

.oops-message {
  font-size: 24px;
  font-weight: bold;
  color: #ff0000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Back button hover effect */
.back-button:hover {
  text-decoration: underline;
  color: #055837;
}

/* Edit button hover effect */
.btn.btn-sm.edit:hover {
  background-color: #ffcc01 !important;
  color: #055837 !important;
}

/* Button container */
.btn-container {
  display: flex;
  justify-content: flex-start;
}

/* Pagination */
.paginate_button_current {
  border: 1px solid rgba(0, 0, 0, 0.3);
  background: linear-gradient(
    rgba(230, 230, 230, 0.1) 0%,
    rgba(0, 0, 0, 0.1) 100%
  );
  color: inherit !important;
  margin-right: 8px;
  cursor: pointer;
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  text-align: center;
  border-radius: 7px;
}

.paginate_button_next,
.paginate_button_previous,
.paginate_button {
  margin-right: 8px;
  border: none;
  background: none;
  cursor: pointer;
  box-sizing: border-box;
  display: inline-block;
  min-width: 1.5em;
  padding: 0.5em 1em;
  text-align: center;
  border-radius: 7px;
}

.paginate_button_next:not(.disabled):hover,
.paginate_button_previous:not(.disabled):hover,
.paginate_button:not(.disabled):hover {
  background-color: black;
  color: white;
  box-sizing: border-box;
  display: inline-block;
  text-align: center;
  cursor: pointer;
  min-width: 1.5em;
  padding: 0.5em 1em;
  text-align: center;
  border-radius: 7px;
}


/* Disable hover effect for disabled buttons */
.paginate_button.disabled {
  cursor: default;
  /* background-color: grey;
  color: white; */
}

/* Disable hover effects when disabled */
.paginate_button.disabled:hover {
  /* background-color: grey;
  color: white; */
  cursor: default;
}


.pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination-buttons {
  display: flex;
}

/* Error message */
.error-message {
  color: red;
  font-size: 14px;
  margin-bottom: 18px;
}

/* Account link container */
.account-link-container {
  display: flex;
  justify-content: center;
}

/* DataTables length select */
.dataTables_length .select-wrapper {
  position: relative;
}

.dataTables_length select {
  width: 60px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  background: transparent;
}

.select-caret-down {
  position: absolute;
  top: 50%;
  right: 10px;
  pointer-events: none;
  transform: translateY(-50%);
  font-size: 14px;
}

.searchInput {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Media queries for specific elements */
@media (max-width: 992px) {
  .dataTables_length,
  .searchInput {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .action_buttons_top .row {
    flex-direction: column;
    align-items: center;
  }
  .dataTables_length,
  .searchInput {
    width: 100%;
  }
  .filter_flag_buttons {
    margin-left: 0;
  }
}

/* For tablets and smaller screens */
@media screen and (max-width: 992px) {
  .submitted-info {
    width: 80%;
  }
  .dropdown-options {
    max-width: 400px;
    width: 90%;
  }
}

/* For smartphones */
@media screen and (max-width: 768px) {
  .submitted-info {
    min-height: auto;
    width: 103vw;
    margin: 0;
    padding: 0;
  }

  .custom-input {
    width: 80%;
  }
  .dropdown-options {
    max-width: 400px;
    width: 90%;
  }
  .btn-container {
    justify-content: space-between;
    position: sticky;
    top: 0;
    z-index: 1;
  }
  .pagination-container {
    flex-direction: column;
    align-items: stretch;
  }
  .pagination-buttons {
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
  }
  .paginate_button,
  .paginate_button_current,
  .paginate_button_previous,
  .paginate_button_next {
    margin: 0 4px;
  }
}
