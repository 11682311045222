.note-form-group {
  padding-bottom: 20px;
}

.note-form-group:last-child {
  padding-bottom: 0;
}

.note-form-label {
  display: block;
  width: 100%;
  font-size: 16px;
  color: #42515f;
  margin-bottom: 10px;
  font-weight: 700;
}

.note-input {
  width: 100%;
  display: block;
  border: 1px solid #ededef;
  background: #fff;
  outline: 0;
  padding: 6px 4px;
  font-size: 14px;
  @include box-sizing();
}


.note-input::-webkit-input-placeholder {
  color: $gray-lighter;
}

.note-input:-moz-placeholder { /* Firefox 18- */
  color: $gray-lighter;
}

.note-input::-moz-placeholder {  /* Firefox 19+ */
  color: $gray-lighter;
}

.note-input:-ms-input-placeholder {
  color: $gray-lighter;
}
