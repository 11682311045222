body {
  height: 100%;
  width: 100%;
  margin: 0;
  overflow-x: hidden;
}

/* Tabs Css Main */

.nav_active_tab_btn {
  font-weight: bold;
  background-color: #055837 !important;
  color: #FFC107 !important;
  
}

.nav_inactive_tab_btn:hover{
  font-weight: bold;
  background-color: #FFC107 !important;
  color: #055837 !important;
}


.nav-link{
  color: #055837;
}

label {
  display: inline-block;
  margin-bottom: 0;
}


.opacity-low{
  opacity: 0.6;
}

.opacity-low:hover{
  cursor: not-allowed !important;
  text-decoration: none !important;
}

.custom_focus_remove:focus{
  outline: none !important;
  box-shadow: none !important;
  border-color: #DEE2E6 !important; /* Optional: Set a neutral border */
}
/* END */